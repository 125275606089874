import { createSlice } from '@reduxjs/toolkit';
import Plans from '@alltrails/core/types/Plans';

const planSlice = createSlice({
  name: 'plans',
  initialState: {} as Plans,
  reducers: {}
});

export default planSlice.reducer;
